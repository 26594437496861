class scriptCache {
  constructor(scripts, force = false) {
    this.loaded = [];
    this.failed = [];
    this.pending = [];
    this.force = force;
    this.load(scripts);
  }

  load(scripts = []) {
    if (!scripts.length) return;
    const scriptPromises = [];
    for (let script of scripts) {
      scriptPromises.push(this.createScriptTag(script))
    }

    return Promise.all(scriptPromises);
  }

  removeScriptTagFromPage(scriptSrc) {
    const CHILD = document.querySelector(`script[src="${scriptSrc}"]`);

    if(CHILD) {
      CHILD.parentNode.removeChild(CHILD);
    }
  }

  createScriptTag(scriptSrc) {
    if (this.force) {
      this.removeScriptTagFromPage(scriptSrc);
    }

    return new Promise((resolve, reject) => {
      let resolved = false;
      let errored = false;
      let body = document.body;
      let tag = document.createElement('script');

      const handleLoad = () => {
        resolved = true;
        resolve(scriptSrc);
      };

      const handleReject = () => {
        errored = true;
        reject(scriptSrc);
      };

      const handleComplete = () => {
        if (resolved) return handleLoad();
        if (errored) return handleReject();

        const status = scriptCache.SCRIPT_STATUS;
        const state = tag.readyState;
        if (state === status.COMPLETE) {
          handleLoad();
        } else if (state === status.ERROR) {
          handleReject();
        }
      };

      tag.async = false;
      // Replace 'onComplete' callback reference in some script tag urls (e.g. Google Maps V3)
      if (scriptSrc.match(/callback=CALLBACK_NAME/)) {
        const onCompleteName = 'onScriptSrcLoaded';
        scriptSrc = scriptSrc.replace(/(callback=)[^&]+/, `$1${onCompleteName}`);
        window[onCompleteName] = handleLoad;

      } else tag.addEventListener('load', handleLoad);

      tag.addEventListener('error', handleReject);
      tag.onreadystatechange = handleComplete;
      tag.src = scriptSrc;
      body.appendChild(tag);

      return tag;
    })
  }
}

export default scriptCache;
