import React, {Component} from 'react';
import Cookies from 'js-cookie';
import qs from 'qs';
import Prismic from 'prismic-javascript';
import { apiEndpoint, accessToken } from '../../config/constants';
import { sitePrefix } from '../../config/constants';

const PREVIEW_EXPIRES = 1/48; // 30 minutes

const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'page' || doc.type === 'basic_page') return "/" + doc.uid.replace(`${sitePrefix}-`, '');

  // Fallback for other types, in case new custom types get created
  return "/doc/" + doc.id;
};

export default class Preview extends Component {

  UNSAFE_componentWillMount() {
    this.preview(this.props);
  }

  preview(props) {
    const params = qs.parse(this.props.location.search.slice(1));
    Prismic.getApi(apiEndpoint, {accessToken})
      .then(api => {
        api.previewSession(params.token, linkResolver, '/')
          .then(url => {
            localStorage.removeItem(`${url}{}`);
            Cookies.set(Prismic.previewCookie, params.token, { expires: PREVIEW_EXPIRES });
            window.previewMode = true;
            props.history.push(url.replace(`${sitePrefix}-`, ''));
          });
      });
  }

  render() {
    return <p>Loading preview...</p>
  }
}
