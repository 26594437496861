import React, { PureComponent } from 'react';

import Page from '../../pages/Page/Page';
import scriptCache from '../../utils/scriptCache';
import { appEnvironment } from '../../config/constants';

const config = {
  attributes: true,
  attributeOldValue: true,
  characterData: true,
  characterDataOldValue: true,
  childList: true,
  subtree: true
};

class Contact extends PureComponent {
  constructor(props) {
    super(props);
    this.scriptCache = null;
    this.force = false;

    this.subscriber = this.subscriber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.url = appEnvironment === 'production'
      ? 'leasing.gplc.uk'
      : 'graingerstage.pexsoftware.com';
  }

  UNSAFE_componentWillMount() {
    this.observer = new MutationObserver(this.subscriber);
    this.observer.observe(document.body, config);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  subscriber(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.target.id === 'g-recaptcha') {
        this.form = document.forms[0];
        this.form.addEventListener('submit', () => this.handleSubmit());
        window.pexComplete = true;
      }
    });
  }

  handleSubmit() {
    window.scrollTo(0, 0);
  }

  loadScripts() {
    if (!this.scriptCache) {
      this.scriptCache = new scriptCache([
        `https://${this.url}/widgets/pex-generic-webform-widget.js`,
        'https://www.google.com/recaptcha/api.js?render=explicit'
      ], this.force);
    }
  }

  render() {
    return <Page {...this.props}>
      <div className="gr-grainger-contact">
        <div className="gr-grainger-form">
          <div className="gr-grainger-form__form">
            <div
              id="pex-generic-webform-widget"
              className="pex-generic-webform-widget"
              api={`https://${this.url}/json-interface`}
              countrydefaultvalue="GB"
              thanksmessage="See you soon at <strong><BUILDING_NAME></strong> in <LOCATION_NAME>"
              webform="Website_register_form"
              ref={() => { this.loadScripts(); }}
            />
          </div>
        </div>
      </div>
    </Page>;
  }
}

export default Contact;
