import PrismicDOM from 'prismic-dom'
import { sitePrefix } from '../../../config/constants';

export const cleanURL = url => url ? url.replace(`${sitePrefix}-`, '') : url;
export const linkResolver = doc => doc ? `/${cleanURL(doc.uid)}` : null;

export const title = (title = '') => title.length ? title[0].text : null;
export const richText = (content = '') => {
  return content.length ? PrismicDOM.RichText.asHtml(content, linkResolver) : null;
};
export const plainText = (text = '') => text.length ? PrismicDOM.RichText.asText(text).trim() : null;

export const link = (link = '', text = '') => {
  if (!text && !(cleanURL(link.url) || cleanURL(link.uid))) return null;

  const linkText = plainText(text) || '';

  if (link.link_type === 'Document') {
    return { url: cleanURL(link.uid) || '#', isExternal: false, text: linkText }
  } else {
    return { url: link.url ? cleanURL(link.url) : '#', isExternal: true, text: linkText }
  }
};

export const imageList = list => list.map(item => ({
    imageurl: item.image ? item.image.url : null,
    link: item.link1 ? item.link1.url : null,
    text: item.text ? item.text[0].text : null
}));

export const yesNo = (answer = '') => answer ? answer.toLowerCase() === 'yes' : null;

export const amenities = amenities => amenities.map(amenity => ({
    title: amenity.title ? plainText(amenity.title) : null,
    explanation: amenity.explanation ? plainText(amenity.explanation) : null,
    icon: amenity.icon ? amenity.icon : null,
    imageUrl: amenity.image ? amenity.image.url : null
}));

export const brandProperties = items => {
  return items.map(property => {
    return {
      title: property.title ? title(property.title) : null,
      description: property.text ? richText(property.text) : null
    }
  });
}

export const gallery = images => images.map(image => ({
  imageurl: image.image ? image.image.url : null
}));

export const floorplans = (items) => {

  const imageItems = {
    apartmentImages: [],
    modalImages: []
  };

    items
      .forEach(item => {
        if(!yesNo(item.is_floorplan_image)) {
          imageItems.apartmentImages.push({
            imageurl: item.image.url ? item.image.url : null,
            text: plainText(item.image_description)
          });
        } else if (yesNo(item.is_floorplan_image)) {
          imageItems.modalImages.push({
            imageurl: item.image.ModalImage
              ? item.image.ModalImage.url || item.image.url
              : item.image.url,
            text: plainText(item.image_description)
          });
        }
      });

  return imageItems;
};

export const propertyManagers = items => items.map(manager => ({
  name: manager.project_manager_name ? title(manager.project_manager_name) : null,
  role: manager.project_manager_role ? title(manager.project_manager_role) : null,
  image: manager.project_manager_image ? manager.project_manager_image : null
}));

export const rentalProcessList = (items) => {
  return items.map(process => ({
    title: process.process_title ? title(process.process_title) : null,
    text: process.process_description ? richText(process.process_description) : null,
    items: process.what_you_ll_need_list ? process.what_you_ll_need_list.map(listItem => listItem.text) : null
  }));
};

export const rentingProcess = (items) => {
  return items.map(process => ({
    subTitle: process.process_title ? title(process.process_title) : null,
    icon: process.icon ? process.icon : null,
    text: process.process_description ? richText(process.process_description) : null,
    checkList: process.check_list ? richText(process.check_list) : null
  }));
};

export const footerPages = (items) => {
  return items.map(page => ({
    cta: page.link && page.text ? link(page.link, page.text) : null
  }));
}

export const footerSocial = items => items.map(social => ({
  icon: social.icon ? social.icon.toLowerCase() : null,
  link: social.link_url ? plainText(social.link_url) : null,
  text: social.text ? plainText(social.text) : null,
}));

export const navigation = items => {
  return items.map(navItem => ({
    title: navItem.link_text ? plainText(navItem.link_text) : null,
    url: navItem.link_location ? `/${cleanURL(navItem.link_location.uid)}` : null,
    isButton: yesNo(navItem.is_button),
  }));
}

export const heroCarousel = items => items.map(item => ({
  tagline: item.tagline ? richText(item.tagline) : null,
  title: item.title ? title(item.title) : null,
  text: item.text ? richText(item.text) : null,
  subtext: item.subtext ? richText(item.subtext) : null,
  cta: item.cta_link && item.cta_text ? link(item.cta_link, item.cta_text) : null,
  imageUrl: item.image ? item.image.url : null,
}));

export const themePicker = (themeColour) => {
  if (themeColour && themeColour.length)
    return themeColour.replace(' ', '-').toLowerCase();
  return null;
};

export const imageTileList = items => {
  const group = {};

  items.forEach(item => {
    const image = {
      src: item.image.url,
      alt: item.image.alt,
      title: title(item.title),
      type: typeof item.type === 'string' ? item.type.split('-')[0] : 'none'
    };

    group[image.type] = group[image.type] ? group[image.type].concat(image) : [image]
  });

  return group;
};

export const commuteList = items => {
  const list = items.reduce((sum, item) => {
    sum[item.from] = Object.keys(item).map(key => ({
      label: key,
      value: item[key]
    }));

    return sum;
  }, {});

  Object.keys(list).map(loc => list[loc].shift());
  return list;
};

export const accreditationLogos = items => items.map(logo => ({
  url: logo.logo ? logo.logo.url : null,
  name: logo.name ? plainText(logo.name) : null,
}));
