import { CMS } from 'base-site-foundation';
import { apiEndpoint, accessToken, sitePrefix } from '../../config/constants';

import * as Components from 'grainger-component-library';
import * as ComponentPropList from './components';

const GlobalConfig = {
  prismic: {
    accessToken: accessToken,
    endpoint: {
      protocol: 'https',
      host: apiEndpoint.replace('/api/v2', '').replace('https://', ''),
      port: '',
      basePath: '/api/v2',
      timeout: 15000,
    },
  }
};

const getSubPages = (page) => {
  const unPrefixedPage = {};
  Object.keys(page).forEach((key) => {
    unPrefixedPage[key.replace(`${sitePrefix}-`, '')] = page[key];
  });
  return unPrefixedPage;
}

const getMainPage = (pageType, page) => {
  if(pageType === 'basic_page') page.meta = page;
  return page;
}

const getPageDetails = (props) => {
  const pathName = props.location.pathname;
  const uid = (pathName === '/') ? 'home' : pathName.slice(1);
  const basicPages = ['terms-of-use', 'privacy-policy', 'cookie-policy'];
  const isBasicPage = basicPages.indexOf(uid) > -1;
  return { uid: `${sitePrefix}-${uid}`, page_type: isBasicPage ? 'basic_page' : 'page' };
};

const getPage = (props) => {
  return new Promise((resolve, reject) => {
    if (!props || typeof props !== 'object') {
      reject(Error('props not defined'));
    } else {
      const details = getPageDetails(props);
      const prismicCMS = new CMS('Prismic', GlobalConfig.prismic);
      const pageTypes = {
        [details.page_type]: [details.uid],
        bar: [`${sitePrefix}-bar`],
        cookie_bar: [`${sitePrefix}-cookie_bar`],
        navigation: [`${sitePrefix}-navigation`],
        footer: [`${sitePrefix}-footer`],
      };
      const sessionData = {
        componentPropList: ComponentPropList,
      };

      prismicCMS.buildPage(sessionData, pageTypes, Components).then((page) => {
        const pageData = {
          main: (page[details.uid]) ? getMainPage(details.page_type, page[details.uid]) : {},
          sub: getSubPages(page),
        };

        if (Object.keys(page[details.uid]).length < 1) {
          pageData.message = 'Page not found';
          reject(pageData);
        } else {
          resolve(pageData);
        }
      }).catch(err => reject(err));
    }
  });
};

export default {
  getPage
};
